<template>
  <b-modal
    id="showAddMaterialCountModal"
    title="Add Material"
    @hidden="resetModal"
    @show="resetModal"
  >
    <div :style="{ 'display': 'flex', 'flexWrap': 'wrap', 'justifyContent': 'space-between', 'width': '90%', 'margin': 'auto', 'height': selectionHeight }">
    <b-form-group id="input-group-3" style="padding:0; margin:0;" label="Material Codes" label-for="input-3">
      <div style="width:100%;">
        <multiselect
          :loading="isLoading"
          v-model="selectedMaterialCodes"
          :options="materialCodes"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="false"
          placeholder="Select Material Code(s)"
          label="name"
          :max="limit"
          track-by="name"
          :preselect-first="true"
          @search-change="fetchMaterialCodes"
          @open="onDropdownOpen"
          @close="onDropdownClose"
        >
          <template
            slot="selection"
            slot-scope="{ values, search, isOpen }"
          >
            <span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} Material Code(s) selected</span>
          </template>
        </multiselect>
      </div>
    </b-form-group>
    </div>
    <template #modal-footer>
      <b-button v-if="disableFormSubmission" :disabled="true" style="margin:auto" size="sm">
        Add Material
      </b-button>
      <b-button
        v-if="!disableFormSubmission && isPermitted(userManagement.user.permissions.addMaterialCount.update)"
        style="margin:auto"
        @click="submitAddMaterialCountRequest"
        size="sm"
        variant="primary"
      >
        Add Material
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { userManagement } from '@/constants/permitted-actions'
import { MY_MATERIAL } from '@/constants/stock-count'
import { MOBILE_MAX_RESULTS, MAX_RESULTS } from '@/constants/shared-constants'
import Multiselect from 'vue-multiselect'
export default {
  name: 'AddMaterialCountModal',
  components: { Multiselect },
  props: {
    stockCount: Object,
    isMobile: Function
  },
  data () {
    return {
      limit: 1,
      selectedMaterialCodes: [], // max 1 material code, limit sets the maximum
      isLoading: false,
      maxResults: this.isMobile() ? MOBILE_MAX_RESULTS : MAX_RESULTS,
      multiSelectOpen: false
    }
  },
  created () {
    this.userManagement = userManagement
  },
  computed: {
    materialCodes () {
      return this.$store.getters['stockCount/materialCodes']
    },
    materialCountItems () {
      return this.$store.getters['materialCount/mobileMaterialCountItems']
    },
    disableFormSubmission () {
      if (this.isPermitted(userManagement.user.permissions.addMaterialCount.update) && this.selectedMaterialCodes.length > 0) {
        return false
      }
      return true
    },
    selectionHeight () {
      // Calculate the height based on the number of selected items
      if (this.multiSelectOpen) {
        if (this.selectedMaterialCodes.length > 0 || this.materialCodes <= 0) return `${140}px` // Adjust the height as needed
        else return `${380}px`
      }
      return `${90}px`
    }
  },
  methods: {
    onDropdownOpen () {
      this.multiSelectOpen = true
    },
    onDropdownClose () {
      this.multiSelectOpen = false
    },
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    resetModal () {
      this.selectedMaterial = ''
    },
    async submitAddMaterialCountRequest () {
      // Only one material can be selected so we take the first from array
      if (this.isPermitted(userManagement.user.permissions.addMaterialCount.update)) {
        const materialCountItems = JSON.parse(JSON.stringify(this.materialCountItems))
        for (let i = 0; i < materialCountItems.length; ++i) {
          if (materialCountItems[i].material_code === this.selectedMaterialCodes[0]?.value) {
            this.$bvModal.hide('showAddMaterialCountModal')
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'The material is already part of the Stock Count!')
            this.$bvModal.show('showSubmissionFailedModal')
            return
          }
        }
        const parameters = { stockCountId: this.stockCount.id, materialCode: this.selectedMaterialCodes[0]?.value }
        await this.$store.dispatch('materialCount/addMaterialCount', parameters)
        this.$bvModal.hide('showAddMaterialCountModal')
        await this.$store.commit('materialCount/clearMaterialCount')
        await this.$store.dispatch('materialCount/fetchNextPage', this.maxResults)
          .then(response => {
            if (response != null && response?.status === 200) {
              this.$emit('after-material-count-added-submit-disabled')
              this.$store.commit('stockCount/setSubmissionSuccessfulTitle', 'Material added to Stock Count')
              this.$bvModal.show('showSubmissionSuccessfulModal')
            } else {
              this.$store.commit('stockCount/setSubmissionFailedTitle', 'Adding Material Failed!')
              this.$bvModal.show('showSubmissionFailedModal')
            }
          })
          .catch(() => {
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Adding Material Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          })
      }
    },
    async fetchMaterialCodes (searchValue) {
      this.isLoading = true
      const queryParameters = { plant: this.stockCount.plant, itemType: MY_MATERIAL, searchValue }
      await this.$store.dispatch('stockCount/fetchItemsNoLoading', queryParameters)
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
</style>
