<template>
  <div>
    <b-list-group style="margin:0; padding:0;" flush>
      <b-list-group-item style="padding-left:0; padding-right:0"><b>{{ messages.getFieldMaterialDescription() }}: </b>{{ item.material_description_english }}</b-list-group-item>
      <b-list-group-item style="margin-left: 0 !important; padding-left:0; padding-right:0"><b>{{ messages.getFieldMaterialCode() }}: </b>
        <b-link
          :href="materialCodeUrl"
          target="_blank"
        >
          {{ item.material_code | formatMissing(messages.getLabelNoMaterialCode()) }}
        </b-link>
      </b-list-group-item>
      <b-list-group-item style="padding-left:0; padding-right:0">
        <b-container style="padding-left:0; padding-right:0" fluid>
          <b-row align-v="start" class="my-1">
            <b-col cols="3" style="padding: 6px 6px;">
              <b-form-text class="quantity" style="padding-left:0; padding-right:0; margin-left:0;">
                {{ messages.getFieldQuantity() }}:
              </b-form-text>
            </b-col>
            <b-col cols="4" v-if="!quantityEditingDisabled" style="padding: 6px 6px;">
              <!-- b form input for quantity color is changed to green after the value has been saved and
              if the input is modified again then color is changed back to white until saving is done. -->
              <b-form-input
                class="material-quantity-input"
                id="material-quantity"
                @change="(val) => changeQuantity(item.id, val)"
                :value="item.quantity"
                :style="[(item.quantity || item.quantity === 0) && !modifiedMaterials.find(el => el.id === item.id) ? {'backgroundColor': '#98FB98'} : {'backgroundColor': '#FFFFFF'}, item.quantity < 0 ? {'border': '1px solid red'} : {}]"
              >
              </b-form-input>
              <p v-if="item.quantity < 0"  class="validation-error" > {{ messages.getErrorQuantityGreaterThanZero() }}</p>
            </b-col>
            <b-col cols="2" v-if="!quantityEditingDisabled" style="padding: 6px 6px;">
              <b-form-text for="material-quantity" style="font-weight: bold;">{{item.unit}}</b-form-text>
            </b-col>
            <b-col cols="6" v-if="quantityEditingDisabled" style="padding: 6px 6px;">
              <b-form-text style="padding-left:0; padding-right:0; font-weight: bold;">{{item.quantity}} {{item.unit}}</b-form-text>
            </b-col>
            <b-col cols="3" style="padding: 6px 6px;">
              <b-button
                :disabled="!isPermitted(userManagement.user.permissions.editMaterialCountQuantity.update) || !canSaveMaterialQuantity"
                squared
                style="padding: 6px"
                variant="primary"
                @click="editMaterialCountQuantities(item.id, item.quantity)"
              >
                {{ messages.getActionSave() }}
              </b-button>
              <b-button
                v-if="isPermitted(userManagement.user.permissions.deleteMaterialCount.get)"
                squared
                :disabled="!canDeleteMaterialCount"
                style="padding: 6px"
                variant="danger"
                @click="openDeleteMaterialCountModal(item)"
              >
                {{ messages.getActionDelete() }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { messages } from '@/utils/strings'
import { formatMissing } from '@/components/StatusMonitor/formatters'
import { userManagement } from '@/constants/permitted-actions'
export default {
  name: 'MaterialCountRow',
  props: {
    item: Object,
    modifiedMaterials: Array,
    canSaveMaterialQuantity: Boolean,
    canDeleteMaterialCount: Boolean
  },
  data () {
    return {
      material_code: this.item.material_code,
      messages
    }
  },
  created () {
    this.userManagement = userManagement
  },
  computed: {
    materialCodeUrl () {
      return this.$store.getters.createMaterialCodeUrl(this.material_code)
    },
    quantityEditingDisabled () {
      return this.$store.getters['stockCount/getQuantityEditingDisabled']
    }
  },
  methods: {
    changeQuantity (id, val) {
      this.$emit('change-quantity', id, val)
    },
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    editMaterialCountQuantities (materialCountId, quantity) {
      this.$emit('edit-material-count-quantities', materialCountId, quantity)
    },
    openDeleteMaterialCountModal (item) {
      this.$emit('open-delete-material-count-modal', item)
    }
  },
  filters: {
    formatMissing
  }
}
</script>

<style scoped>
.mobile {
  width: 100%;
  margin: 0;
}
.material-unit {
  margin-left: 10px;
  font-weight: bold;
}
.quantity {
  font-weight: bold;
  color: #3B4245 !important;
  font-size: 16px;
}
.validation-error {
  color: red;
}
</style>
