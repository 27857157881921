<template>
  <page-layout>
    <filter-panel v-if="!isMobile()" class="w-100" />
    <template v-slot:topBar v-if="isMobile()">
      <top-panel class="d-block d-lg-none" />
    </template>
    <div style="width:100%; padding-top:10px; box-sizing:border-box; margin:0; padding:30px 0 0 0;">
      <div style="width:100%;padding-top: 20px;">
        <div v-if="isMobile() && searchText" class="py-3; w-100">
          <search-indicator
            v-show="searchText"
            @reset="resetSearch"
          >
            {{ searchText }}
          </search-indicator>
        </div>
        <b-input-group
          v-else
          class="mb-3"
        >
          <div>
            <b-link to="/notifications" style="padding: 20px; box-sizing: border-box;">{{ messages.getActionGoBack() }}</b-link>
            <cancel-modal :id="requestToBeCancelled" />
          </div>
          <b-button
            id='cancelButton'
            @click="cancel(stockCount.id)"
            :disabled="!canCancel"
            squared
            variant="danger"
            v-if="isPermitted(userManagement.user.permissions.cancelStockCount.get)"
          >
            {{ messages.getActionButtonCancel() }}
          </b-button>
          <b-button
            v-if="isPermitted(userManagement.user.permissions.submitStockCount.get)"
            id='submitStockCountButton'
            @click="submitStockCount(stockCount.id)"
            variant="success"
            squared
            :disabled="!canSubmit"
          >
            {{ messages.getActionButtonSubmitStockCount() }}
          </b-button>
          <b-button
            v-if="isPermitted(userManagement.user.permissions.addMaterialCount.get)"
            :disabled="!canAddOrEditMaterial"
            @click="$bvModal.show('showAddMaterialCountModal')"
          >
            {{ messages.getActionButtonAddMaterial() }}
          </b-button>
          <b-button
            variant='outline-primary'
            @click="printMaterials()"
          >
            {{ messages.getActionButtonPrintMaterials() }}
          </b-button>
          <b-button
            variant='primary'
            @click="editMaterialCountQuantities()"
            :disabled="!isPermitted(userManagement.user.permissions.editMaterialCountQuantity.update) || !canSaveMaterialQuantity"
          >
            {{ messages.getActionButtonSaveAll() }}
          </b-button>
        </b-input-group>
      </div>
      <span v-if="isMobile()" class="mobile-sorting">
        <order-count
        :order-by-options='fields'
        :order-by-column='orderByColumn'
        :sort-direction='sortDirection'
        :sort-direction-value='sortDirectionValue'
        @change-order-by-column='changeOrderByColumn'
        @change-sort-direction='changeSortDirection'
      />
      </span>
      <div style="width:100%;">
        <div class="mobile" v-if="isMobile()">
          <b-card style="width: 100%; margin-bottom: 1rem;" v-for="(item, index) in materialCountItems"  :key="index" :title="item.material_code">
            <material-count-row
              :item='item'
              @change-quantity='changeQuantity'
              @edit-material-count-quantities='editMaterialCountQuantities'
              :modified-materials='modifiedMaterials'
              @open-delete-material-count-modal='openDeleteMaterialCountModal'
              :can-save-material-quantity='canSaveMaterialQuantity'
              :can-delete-material-count='canDeleteMaterialCount'
            />
          </b-card>
        </div>
        <div v-if="!isMobile()">
          <b-table
            bordered
            :fields="fields"
            :items="materialCountItems"
            :no-local-sorting='true'
            responsive="sm"
            :sort-by.sync="orderByColumn"
            :sort-desc.sync="sortDirection"
            :sort-direction="sortDirectionWhenChangingTab"
            sort-icon-right
            striped
          >
            <template #cell(material_code)="data">
              <b-link
                :href="materialCodeUrl(data.item.material_code)"
                target="_blank"
              >
                {{ data.item.material_code }}
              </b-link>
            </template>
            <template #cell(quantity)="data">
                <b-row class="my-1">
                  <b-col v-if="isPermitted(userManagement.user.permissions.editMaterialCountQuantity.update) && !quantityEditingDisabled">
                    <div class="row">
                      <div class="col-xs-8">
                        <b-form-input
                          :value="data.item?.quantity"
                          @change="(val) => changeQuantity(data.item?.id, val)"
                          type="number"
                          step="1"
                          min="0.000"
                          max="9999999999999.999"
                          id="material-quantity"
                          size="sm"
                          class="material-quantity-input"
                            :style="[(data.item.quantity|| data.item?.quantity === 0) && !modifiedMaterials.find(el => el.id === data.item.id) ? {'backgroundColor': '#98FB98'} : {'backgroundColor': '#FFFFFF'}, data.item.quantity < 0 ? {'border': '1px solid red'} : {}]"
                        >
                        </b-form-input>
                        <p v-if="data.item?.quantity < 0"  class="validation-error" > {{ messages.getErrorQuantityGreaterThanZero() }}</p>
                      </div>
                      <div class="col-xs-4">
                        <b-form-text class="material-unit">{{data.item?.unit}}</b-form-text>
                    </div>
                    </div>
                  </b-col>
                  <b-col v-else sm="6">
                    <p style="font-weight: bold;">{{data.item?.quantity}} {{data.item?.unit}}</p>
                  </b-col>
                </b-row>
            </template>
            <template #cell(action)="data">
              <b-button
                :disabled="!isPermitted(userManagement.user.permissions.editMaterialCountQuantity.update) || !canSaveMaterialQuantity"
                @click="editMaterialCountQuantities(data?.item.id, data?.item.quantity)"
                variant="primary"
              >
                {{ messages.getActionSave() }}
              </b-button>
              <b-button
                v-if="isPermitted(userManagement.user.permissions.deleteMaterialCount.get)"
                :disabled="!canDeleteMaterialCount"
                @click="openDeleteMaterialCountModal(data.item)"
                variant="danger"
              >
                {{ messages.getActionDelete() }}
              </b-button>
            </template>
            <template v-slot:custom-foot class="desktop-pagination-container">
              <b-tr id="footer-tr" class="mx-auto">
                <b-td colspan="9">
                  <div
                    class="desktop-pagination-previous"
                  >
                    <b-button
                      v-show="hasPrev"
                      :disabled="ongoingQuery"
                      squared
                      class="text-uppercase text-primary"
                      @click="previousPage"
                    >
                      {{ messages.getActionPreviousPage() }}
                    </b-button>
                  </div>
                  <div
                    class="desktop-pagination-next"
                  >
                    <b-button
                      v-show="hasNext"
                      :disabled="ongoingQuery"
                      squared
                      class="text-uppercase text-primary"
                      @click="nextPage"
                    >
                      {{ messages.getActionNextPage() }}
                    </b-button>
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <submission-successful title='Quantities Saved!'></submission-successful>
    <submission-failed></submission-failed>
    <add-material-count-modal
      :stockCount="stockCount"
      :isMobile="isMobile"
      @after-material-count-added-submit-disabled="afterMaterialCountAddedSubmitDisabled">
    </add-material-count-modal>
    <delete-material-count-modal :materialCount="materialCount"></delete-material-count-modal>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import PageLayout from '../components/PageLayout'
import SubmissionSuccessful from '../components/MechanicView/SubmissionSuccessful'
import SubmissionFailed from '../components/MechanicView/SubmissionFailed'
import TopPanel from '../components/Notifications/Mobile/NotificationTopPanel'
import CancelModal from '../components/NotificationsView/CancelModal'
import MaterialCountRow from '../components/Notifications/Mobile/MaterialCountRow'
import AddMaterialCountModal from '../components/MechanicView/AddMaterialCountModal'
import DeleteMaterialCountModal from '../components/MechanicView/DeleteMaterialCountModal'
import FilterPanel from '../components/Notifications/Desktop/FilterPanel'
import SearchIndicator from '../components/Search/SearchIndicator'
import { SUPER_MANAGER } from '@/constants/permissions'
import { CANCELLED, COMPLETED, SUBMITTED, ERROR, IN_PROGRESS, INITIATED, RECOUNT, QUANTITY, ASC, DESC } from '@/constants/stock-count'
import { MAX_RESULTS, MOBILE_MAX_RESULTS } from '@/constants/shared-constants'
import OrderCount from '../components/Notifications/Mobile/OrderCount'
import { userManagement } from '@/constants/permitted-actions'
import printJS from 'print-js'
let INFINITE = null

export default {
  name: 'MechanicPage',
  components: {
    PageLayout,
    SubmissionSuccessful,
    SubmissionFailed,
    CancelModal,
    AddMaterialCountModal,
    DeleteMaterialCountModal,
    TopPanel,
    MaterialCountRow,
    FilterPanel,
    SearchIndicator,
    OrderCount
  },
  data () {
    return {
      canCancel: false,
      canSubmit: false,
      canAddOrEditMaterial: false,
      canDeleteMaterialCount: false,
      canSaveMaterialQuantity: false,
      materialCount: {},
      modifiedMaterials: [],
      quantity: 0,
      requestToBeCancelled: null,
      rows: 0,
      orderByColumn: this.$store.getters['materialCount/orderByColumn'] || QUANTITY,
      sortDirection: this.$store.getters['materialCount/sortDirection'] || false, // false means ascencing order and true descending order
      sortDirectionWhenChangingTab: 'last', // last means use the sortDirection that was last used
      fields: [
        { label: messages.getFieldMaterialDescription(), key: 'material_description_english', sortable: true },
        { label: messages.getFieldMaterialCode(), key: 'material_code', sortable: true },
        { label: messages.getFieldQuantity(), key: 'quantity', sortable: true },
        { label: messages.getTableColumnActions(), key: 'action', sortable: false }
      ],
      messages
    }
  },
  async created () {
    this.userManagement = userManagement
    await this.$store.commit('materialCount/clearMaterialCountItems')
  },
  computed: {
    materialCountItems: {
      get () {
        if (this.isMobile()) {
          return this.$store.getters['materialCount/mobileMaterialCountItems']
        }
        return this.$store.getters['materialCount/materialCountItems'](this.page)
      },
      set (parameters) {
        this.$store.commit('materialCount/editMaterialCountQuantities', parameters)
      }
    },
    userIsSuperManager () {
      return this.$store.getters.hasCurrentUserPermission(SUPER_MANAGER)
    },
    quantityEditingDisabled: {
      get () {
        return this.$store.getters['stockCount/getQuantityEditingDisabled']
      },
      set (value) {
        this.$store.commit('stockCount/setDisableQuantityEditing', value)
      }
    },
    page: {
      get () {
        return this.$store.getters['materialCount/page']
      },
      set (page) {
        this.$store.commit('materialCount/setPage', page)
      }
    },
    lastPage () {
      return this.$store.getters['materialCount/lastPage']
    },
    hasPrev () {
      return this.$store.getters['materialCount/hasPrev']
    },
    hasNext () {
      return this.$store.getters['materialCount/hasNext']
    },
    ongoingQuery () {
      return this.$store.getters['materialCount/ongoingQuery']
    },
    orderByColumnUpdated () {
      return this.orderByColumn
    },
    sortDirectionUpdated () {
      return this.sortDirection
    },
    searchText () {
      return this.$store.getters['materialCount/searchParts'].join(' ')
    },
    sortDirectionValue () {
      return this.sortDirection ? DESC : ASC
    },
    maxResults () {
      return this.isMobile() ? MOBILE_MAX_RESULTS : MAX_RESULTS
    },
    stockCount: {
      get () {
        return this.$store.getters['stockCount/currentStockCount']
      },
      set (stockCount) {
        this.$store.commit('stockCount/setCurrentStockCount', stockCount)
      }
    }
  },
  methods: {
    changeOrderByColumn (column) {
      this.orderByColumn = column
    },
    changeSortDirection (direction) {
      this.sortDirection = direction === DESC ? true : false // eslint-disable-line
    },
    afterMaterialCountAddedSubmitDisabled () {
      // It is not possible to submit Stock Count after new Material has been added until quantity field is filled in.
      this.canSubmit = false
    },
    resetSearch () {
      const queryParameters = {
        query: []
      }
      this.$store.dispatch('materialCount/search', queryParameters)
    },
    materialCodeUrl (materialCode) {
      return this.$store.getters.createMaterialCodeUrl(materialCode)
    },
    async printMaterials () {
      const queryParameters = {
        orderByColumn: this.orderByColumn,
        sortDirection: this.sortDirection ? 'desc' : 'asc',
        stockCountId: this.$route.params.id
      }
      await this.$store.commit('materialCount/setOrderByColumn', this.orderByColumn)
      await this.$store.commit('materialCount/setSortDirection', this.sortDirection ? 'desc' : 'asc')
      await this.$store.dispatch('materialCount/fetchMaterialsForPrinting', queryParameters)
      const materialsToPrint = await this.$store.getters['materialCount/materialsToPrint']
      printJS({
        printable: materialsToPrint,
        type: 'json',
        properties: [
          { field: 'material_description_english', displayName: 'Material Description' },
          { field: 'material_code', displayName: 'Material Code' },
          { field: 'quantity', displayName: 'Quantity' },
          { field: 'unit', displayName: 'Unit' }
        ],
        header: `<p>Plant: ${this.stockCount.plant}, Storage Location: ${this.stockCount.storage_location}, Work Center: ${this.stockCount.work_center}, Deadline: ${this.stockCount.should_be_done_at}, Conductor: ${this.stockCount.conducted_by}</p>`
      })
    },
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    openDeleteMaterialCountModal (item) {
      this.materialCount = item
      this.$bvModal.show('showDeleteMaterialCountModal')
    },
    stockCountCanAddMaterial () {
      if (
        this.stockCount?.status?.toLowerCase() === IN_PROGRESS ||
        this.stockCount?.status?.toLowerCase() === INITIATED ||
        this.stockCount?.status?.toLowerCase() === ERROR
      ) {
        return true
      }
      return false
    },
    savingMaterialQuantityAllowed () {
      if (
        this.stockCount?.status?.toLowerCase() === IN_PROGRESS ||
        this.stockCount?.status?.toLowerCase() === INITIATED ||
        this.stockCount?.status?.toLowerCase() === RECOUNT ||
        this.stockCount?.status?.toLowerCase() === ERROR
      ) {
        return true
      }
      return false
    },
    deletingMaterialCountAllowed () {
      if (
        this.stockCount?.status?.toLowerCase() === IN_PROGRESS ||
        this.stockCount?.status?.toLowerCase() === INITIATED ||
        this.stockCount?.status?.toLowerCase() === RECOUNT ||
        this.stockCount?.status?.toLowerCase() === ERROR
      ) {
        return true
      }
      return false
    },
    stockCountCanBeCancelled () {
      return this.stockCount?.status?.toLowerCase() === INITIATED
    },
    async stockCountCanBeSubmitted () {
      if (this.isPermitted(userManagement.user.permissions.submitStockCount.update)) {
        this.stockCount = this.$store.getters['materialCount/stockCount']
        if (
          this.stockCount?.status?.toLowerCase() === ERROR ||
          this.stockCount?.status?.toLowerCase() === IN_PROGRESS ||
          this.stockCount?.status?.toLowerCase() === RECOUNT
        ) {
          // !!!VERIFY THAT ALL MATERIALS HAVE QUANTITY BEFORE SUBMIT CAN BE DONE!!!
          const allMaterialsHaveQuantity = await this.verifyAllMaterialsHaveQuantity()
          if (allMaterialsHaveQuantity) {
            return true
          }
        }
      }
      return false
    },
    submitStockCount (stockCountId) {
      const parameters = { stockCountId }
      this.$store.dispatch('stockCount/submitStockCount', parameters)
        .then(async (response) => {
          if (response != null && response?.status === 200) {
            this.canSubmit = false
            this.canAddOrEditMaterial = false
            this.canSaveMaterialQuantity = false
            this.canDeleteMaterialCount = false
            await this.$store.commit('stockCount/setCurrentStockCountStatus', COMPLETED)
            await this.$store.commit('stockCount/setDisableQuantityEditing', true)
            this.$store.commit('stockCount/setSubmissionSuccessfulTitle', 'Stock Count submitted successfully!')
            this.$bvModal.show('showSubmissionSuccessfulModal')
          } else {
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Submitting Stock Count Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          }
        })
        .catch(() => {
          this.$bvModal.show('showSubmissionFailedModal')
        })
    },
    cancel (stockCountId) {
      this.requestToBeCancelled = stockCountId
      this.$bvModal.show('cancelModal')
    },
    async changeQuantity (id, val) {
      this.materialCountItems = await this.materialCountItems.map(el => {
        if (el.id === id) {
          el.quantity = parseFloat(val)
          this.canSaveMaterialQuantity = el.quantity >= 0
          this.modifiedMaterials.push(el) // For saveAll we make a list of modified materials
        }
        return el
      })
    },
    async verifyAllMaterialsHaveQuantity () {
      const queryParameters = {
        stockCountId: this.$route.params.id
      }
      // materialsToPrint loads all materials to the frontend so it can be used here to do just that.
      await this.$store.dispatch('materialCount/fetchMaterialsForPrinting', queryParameters)
      const materialCountItems = await this.$store.getters['materialCount/materialsToPrint']
      const materialCountTotal = await this.$store.getters['materialCount/total']
      // If we have not loaded the materialsToPrint yet or if we have added new materials to the Stock Count
      // then we have to load the list of all materials.
      if (materialCountItems.length === 0 || materialCountTotal > materialCountItems?.length) {
        return false
      }
      if (materialCountTotal > materialCountItems?.length) {
        // There are more items in this Stock Count than are loaded in the UI so we can't decide if the submitting is possible
        // before all are loaded to UI.
        return false
      }
      for (const material of materialCountItems) {
        // All items are loaded to UI so we can check if all quantities are filled and if not then return false.
        if (material.quantity == null || material.quantity === '') {
          return false
        }
      }
      return true
    },
    // This method can be used to edit a single quantity or all modified quantities.
    editMaterialCountQuantities (materialCountId, quantity) {
      let cleanedDataForSaving = []
      let successText = 'Quantity edited successfully!'
      if (materialCountId && quantity) {
        // Edit single quantity (Save button).
        cleanedDataForSaving.push({ materialCountId, quantity })
      } else {
        // Edit all modified quantities (Save all button).
        cleanedDataForSaving = this.modifiedMaterials.map(el => {
          return { materialCountId: el.id, quantity: el.quantity }
        })
        if (cleanedDataForSaving.length > 1) {
          successText = 'Quantities edited successfully!'
        }
      }
      this.$store.dispatch('materialCount/editMaterialCountQuantities', cleanedDataForSaving)
        .then(async () => {
          this.$store.commit('stockCount/setSubmissionSuccessfulTitle', successText)
          this.$bvModal.show('showSubmissionSuccessfulModal')
          this.canSubmit = await this.stockCountCanBeSubmitted()
          this.canCancel = await this.stockCountCanBeCancelled()
          this.canDeleteMaterialCount = await this.deletingMaterialCountAllowed()
          this.canSaveMaterialQuantity = await this.savingMaterialQuantityAllowed()
          if (materialCountId && quantity) {
            this.modifiedMaterials = this.modifiedMaterials.filter(material => material.id !== materialCountId)
          } else {
            this.modifiedMaterials = []
          }
        }).catch((error) => {
          this.$store.commit('stockCount/setSubmissionFailedTitle', error)
          this.$bvModal.show('showSubmissionFailedModal')
        })
    },
    async fetchMaterials () {
      // Used when user click view from stock count to see materials.
      if (this.ongoingQuery) {
        return
      }
      await this.$store.commit('materialCount/clearMaterialCount')
      await this.$store.dispatch('materialCount/fetchNextPage', this.maxResults)
    },
    isMobile () {
      return this.$screen.width <= 991
    },
    nextPage () {
      if (this.page === this.lastPage.index) {
        if (!this.lastPage.hasNext) {
          throw Error('No pages to fetch')
        }
        this.$store.dispatch('materialCount/fetchNextPage', this.maxResults)
          .then(() => {
            this.page = this.lastPage.index
          })
      } else {
        this.page = this.page + 1
      }
    },
    previousPage () {
      if (this.page === 0) {
        // button should be disabled
      }
      // this should not do the query as the previous page is stored already
      this.page = this.page - 1
    },
    infiniteScroll () {
      INFINITE = window.onscroll = () => {
        if (this.isMobile()) {
          this.mobileView = true
          const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.body.scrollHeight - window.innerHeight
          // When we reach the bottom of window we load more items from database
          if (bottomOfWindow && !this.ongoingQuery) {
            if (this.hasNext) {
              const viewWrapper = document.scrollingElement
              const scrollPosition = viewWrapper.scrollTop
              this.$store.dispatch('materialCount/fetchNextPage', this.maxResults).then(() => {
                viewWrapper.scrollTop = scrollPosition
              })
            }
          }
        } else {
          this.mobileView = false
        }
      }
      INFINITE()
    },
    async updatedDataOrderAndSortDirection () {
      // This gets called by watch order by column or sort direction is changed.
      await this.$store.commit('materialCount/clearMaterialCount')
      await this.$store.commit('materialCount/setOrderByColumn', this.orderByColumn)
      await this.$store.commit('materialCount/setSortDirection', this.sortDirection)
      await this.$store.dispatch('materialCount/fetchNextPage')
    },
    async validateQuantityEditingDisabled (stockCount) {
      const quantityEditingDisabled = (
        stockCount?.status?.toLowerCase() === CANCELLED ||
        stockCount?.status?.toLowerCase() === COMPLETED ||
        stockCount?.status?.toLowerCase() === SUBMITTED
      )
      await this.$store.commit('stockCount/setDisableQuantityEditing', quantityEditingDisabled)
    }
  },
  async mounted () {
    if (this.$route.params.stockCount) {
      this.stockCount = this.$route.params.stockCount
    } else {
      // this would be called on page refresh
      await this.$store.dispatch('stockCount/fetchStockCountItem', this.$route.params.id)
    }
    this.validateQuantityEditingDisabled(this.stockCount)
    this.$store.commit('materialCount/setStockCount', this.stockCount)
    this.$store.commit('materialCount/setStockCountId', this.$route.params.id)
    this.infiniteScroll()
    await this.fetchMaterials()
    this.canSubmit = await this.stockCountCanBeSubmitted()
    this.canCancel = await this.stockCountCanBeCancelled()
    this.canDeleteMaterialCount = await this.deletingMaterialCountAllowed()
    this.canSaveMaterialQuantity = await this.savingMaterialQuantityAllowed()
    this.canAddOrEditMaterial = await this.stockCountCanAddMaterial()
  },
  beforeDestroy () {
    this.$store.commit('materialCount/clearMaterialsToPrint')
    this.$store.commit('materialCount/clearMaterialCount')
    window.removeEventListener('scroll', this.INFINITE)
  },
  watch: {
    async orderByColumnUpdated () {
      await this.updatedDataOrderAndSortDirection()
    },
    async sortDirectionUpdated () {
      await this.updatedDataOrderAndSortDirection()
    }
  }
}
</script>
<style scoped>
.mobile {
  width: 100%;
  margin: 0;
}
.material-unit {
  margin-left: 10px;
  font-weight: bold;
}
.quantity {
  font-weight: bold;
  color: #3B4245 !important;
  font-size: 16px;
}
.mobile-sorting {
  height: 100%;
  width: 100%;
  margin: 0 0 0 15px;
}
#submitStockCountButton {
  float: right;
}
#cancelButton {
  float: right;
}
.desktop-pagination-container {
  display: flex;
  margin-bottom: 20px;
}
.desktop-pagination-previous {
  float: left;
}
.desktop-pagination-next {
  float: right;
}
#footer-tr {
  flex-direction: column;
}
.validation-error {
  color: red;
}
</style>
