<template>
  <b-row no-gutters class="w-100 pt-4">
    <b-col class="search-options-container">
      <search-box-multi
        v-model="searchParts"
        :hint-text="messages.getLabelSearchForMaterialCount()"
      />
    </b-col>
    <b-col class="d-flex justify-content-end">
      <!-- use v-if to hide calendar -->
      <b-calendar v-if="false" hide-header/>
    </b-col>
  </b-row>
</template>

<script>
/**
 * This is desktop view only panel containing:
 *   1. Free-text search bar
 *   2. Selectable filter buttons for all, arrived and incoming trackings.
 *   3. Calendar widget.
 */
import { messages } from '@/utils/strings'
import SearchBoxMulti from '@/components/FilterPanel/SearchBoxMulti'

export default {
  name: 'FilterPanel', // FIXME: See that this is not confused with StockFilterMenu
  components: { SearchBoxMulti },
  data () {
    return {
      // We need to use model and store the search text here as the SearchOptions
      // can change independently and the event payload does not contain the
      // free text search.
      // The searchParts in the Vuex store is updated by the action "search".
      searchParts: [],
      messages
    }
  },
  watch: {
    searchParts (newValue) {
      this.change()
    }
  },
  methods: {
    /**
     * Handle user search event.
     *
     * @param {Object} event - Event payload from SearchBoxMulti or SearchOptions
     * @param {String} event.source - Source where the event was fired.
     * @param {any} event.value - Optional payload.
     */
    change (event) {
      // console.log({ _: 'FilterPanel.change', event, searchParts: this.searchParts })
      //
      // In practise we ignore the event source and payload as the end result is
      // the same: Make a new search.
      //
      // Because we are only passing the query parameter, the "stockManagement/search"
      // action is using the values stored in Vuex and updated by the SearchOptions
      // component.
      //
      // The searchParts is also stored in Vuex, but the "stockManagement/search" has
      // query as a required parameter. We could also use a Vuex getter to provide
      // that parameter.
      const queryParameters = {
        query: this.searchParts
      }
      this.$store.dispatch('materialCount/search', queryParameters)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
