<template>
    <div>
      <b-modal
        id="showDeleteMaterialCountModal"
        title="Delete Material"
        size="sm"
        centered
        @ok="deleteMaterialCount"
        ok-title="Delete Material"
        ok-variant="danger"
      >
        <div class="d-flex justify-content-center align-items-center">
          <p class="my-4 text-center" style="font-weight: bold">
            Are you sure you want to delete the material {{materialCount.material_code}}, {{materialCount.material_description_english}} ?
          </p>
         </div>
      </b-modal>
    </div>
</template>
<script>
import { userManagement } from '@/constants/permitted-actions'
export default {
  name: 'DeleteMaterialCount',
  props: {
    materialCount: Object
  },
  methods: {
    isPermitted (permittedAction, permissionIds) {
      return this.$store.getters.isPermitted(permittedAction, permissionIds)
    },
    async deleteMaterialCount () {
      if (this.isPermitted(userManagement.user.permissions.deleteMaterialCount.update)) {
        await this.$store.dispatch('materialCount/deleteMaterialCount', { materialCountId: this.materialCount.id })
          .then(response => {
            if (response != null && response?.status === 204) {
              this.$store.commit('stockCount/setSubmissionSuccessfulTitle', 'Material deleted successfully')
              this.$bvModal.show('showSubmissionSuccessfulModal')
            } else {
              this.$store.commit('stockCount/setSubmissionFailedTitle', 'Deleting Material Failed!')
              this.$bvModal.show('showSubmissionFailedModal')
            }
          })
          .catch(() => {
            this.$store.commit('stockCount/setSubmissionFailedTitle', 'Deleting Material Failed!')
            this.$bvModal.show('showSubmissionFailedModal')
          })
        await this.$store.getters['materialCount/materialCountItems'](this.page)
        this.$bvModal.hide('showDeleteMaterialCountModal')
      }
    }
  },
  created () {
    this.userManagement = userManagement
  }
}
</script>
<style scoped>
</style>
